function fetchConfig() {
  return {
    headers: {
      token: window.BARREL.sbdApiAccessToken
    }
  }
}

async function fetchAPI(path, config) {
  const url = new URL(window.BARREL.sbdApiUrl)
  url.pathname = path

  const response = await fetch(url, config)

  if (!response.ok) {
    throw response
  }

  return response.json()
}

export async function addToWishlist(payload) {
  const config = fetchConfig()
  config.method = 'POST'
  config.headers['Content-Type'] = 'application/json'
  config.body = JSON.stringify(payload)

  return fetchAPI('/api/addWishListItem', config)
}

async function getProductsByIds(ids, nodes) {
  try {
    if (ids && ids.length === 0) return;

    const query = `query {
                  nodes(ids: ${JSON.stringify(ids.map(e => `gid://shopify/Product/${e}`))}) {
                      id
                      ... on Product {
                      images(first: 250) {
                        nodes {
                          id
                          url
                        }
                      }
                      collections(first: 250) {
                        nodes {
                          id
                          handle
                          title
                        }
                      }
                      variants(first: 250) {
                        nodes {
                          id
                          title
                          sku
                          quantityAvailable
                          availableForSale
                          price {
                            amount
                          }
                          compareAtPrice {
                            amount
                          }
                          barcode
                        }
                      }
                      productType
                      vendor
                      title
                      handle
                      featuredImage {
                        url
                      }
                      priceRange {
                        maxVariantPrice {
                          amount
                        }
                        minVariantPrice {
                          amount
                        }
                      }
                      totalInventory
                      availableForSale
                      tags
                    }
                }
              }`

    const response = await fetch(
      `https://${window.BARREL.shop}/api/2024-04/graphql.json`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Storefront-Access-Token": window.BARREL.storefrontAccessToken,
        },
        body: JSON.stringify({ query }),
      }
    );

    const responseData = await response.json();
    if (responseData.errors && responseData.errors.length) {
      throw new Error(JSON.stringify(responseData.errors));
    }
    let products = []
    if (responseData && responseData.data) {
      products = responseData.data.nodes.filter(Boolean);
    }

    return products;
  } catch (error) {
    console.error("Error in getProductsByIds ", error);
    return;
  }
}

export async function getWishlist() {
  try {
    const config = fetchConfig()
    const pageSize = 1000

    const response = await fetchAPI(`/api/getWishListItems/${window.BARREL.customerId}?pageSize=${pageSize}`, config);
    const wishlistItems = response.data;
    // console.log(wishlistItems);
    if (!wishlistItems?.length) {
      return { data: [] }
    };

    let productIds = wishlistItems.map(({ ProductID }) => ProductID);
    const products = await getProductsByIds(productIds);
    let mappedProducts = []
    console.log("Before Products", { products });
    if (!products?.length) {
      return { data: [] }
    };

    products.forEach((item) => {
      let legacyProductId = item.id.replace('gid://shopify/Product/', '')
      const mapItem = {
        id: legacyProductId,
        handle: item.handle,
        images: item.images.nodes.map((image) => ({ id: image.id.replace('gid://shopify/ProductImage/', ''), src: image.url })),
        productCollections: item.collections.nodes.map((collection) => ({
          id: collection.id,
          handle: collection.handle,
          title: collection.title,
        })),
        title: item.title,
        type: item.productType,
        variants: item.variants.nodes.map((variant) => ({
          id: variant.id.replace("gid://shopify/ProductVariant/", ""),
          title: variant.title,
          sku: variant.sku,
          // inventoryQuantity: 0,
          availableForSale: variant.availableForSale,
          price: variant.price?.amount || null,
          compareAtPrice: variant.compareAtPrice?.amount || null,
          barcode: variant.barcode,
        })),
        vendor: item.vendor,
        featuredImage: item.featuredImage?.url || "",
        price: item.priceRange?.minVariantPrice?.amount || null,
        compareAtPrice: item.priceRange?.maxVariantPrice?.amount || null,
        // PRODUCT_HEIGHT_US: "18.3 IN",
        // PRODUCT_TYPE: "Leaf Blowers",
        // POWER_TYPE: "ELECTRICITY",
        // PRODUCT_APPLICATION: "Yard Work",
        availableForSale: item.availableForSale,
        // onSale: false,
        // Rating: 3.6278,
        tags: item.tags
      };
      mappedProducts.push(mapItem);
    });

    console.log("After Products", { mappedProducts })
    let mappedWishlistItems = []
    wishlistItems.forEach((item) => {
      let product = mappedProducts.find((e) => e.id === item.ProductID);
      mappedWishlistItems.push({
        wishListItemID: item.WishListItemID,
        customerID: item.CustomerID,
        productID: item.ProductID,
        title: item.Title,
        handle: item.Handle,
        variantID: item.VariantID,
        qty: item.Qty,
        createdDate: item.CreatedDate,
        product: product || null
      })
    })
    console.log("wishlistItems", { mappedWishlistItems })

    return { data: mappedWishlistItems }
  } catch (error) {
    console.error('Error while getting wishlist', error.message)
  }
}

export async function removeFromWishlist(payload) {
  const config = fetchConfig()
  config.method = 'POST'
  config.headers['Content-Type'] = 'application/json'
  config.body = JSON.stringify(payload)

  return fetchAPI('/api/removeWishListItem', config)
}
