/**
 * Theme.js
 */

import '../scripts/accessibility'
import '../scripts/aos'
import "../scripts/algoliaInstantSearch";
import '@/scripts/flying-scripts'
import { getWishlist } from '@/services/wishlist'
import revive from '@/scripts/revive'

// Islands
const islands = import.meta.glob('../../modules/**/*.(js|jsx)')
revive(islands)

  // globals
  ; (async () => {
    // wishlist
    if (window.BARREL.customerId) {
      window.BARREL.wishlist = await getWishlist()
      const event = new window.CustomEvent('wishlistLoaded', { detail: window.BARREL.wishlist })
      setTimeout(() => {
        window.document.dispatchEvent(event)
      }, 500)

    }
  })()

  let showApps = false
  const userInteractionEvents = ["scroll", "mousedown", "mousemove", "click", "keydown", "touchstart"]
  userInteractionEvents.forEach( event => {
    window.addEventListener(event, () => {
      if (!showApps) {
        showApps = true
        document.body.classList.add('show-apps-hidden')
      }
    }, { passive: true })
  })