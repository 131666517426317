document.addEventListener("algolia.hooks.initialize", function () {
  let algoliaShopify = window.algoliaShopify;
  let allZeroPrice = 0;
  // console.log("algolia.hooks.initialize");

  algoliaShopify.hooks.registerHook(
    "beforeAutocompleteNoResultsTemplate",
    function (_defaultTemplate, templateOptions) {
      const { html, state } = templateOptions;

      if (state.query === "") return;

      let products = window.saleItems?.length
        ? window.saleItems.map((item, index) => { // Corrected map function syntax
          return html`<li class="aa-Item" id="autocomplete-0-saleItems-item-${index}" role="option" aria-selected="false">
        <a href="/products/${item.handle}/?variant=${item.variant_id}" class="aa-ItemLink aa-ProductItem">
          <div class="aa-ItemContent">
            <div class="aa-ItemPicture aa-ItemPicture--loaded">
              <img src="${item.product_image}" srcset="${item.product_image}" width="125px" height="125px"
                alt="${item.title}" />
            </div>
            <div class="aa-ItemContentBody">
              <div class="aa-ItemContentBrand">bev<span> by </span>BLACK+DECKER</div>
              <div class="aa-ItemContentTitleWrapper">
                <div class="aa-ItemContentTitle">${item.title}<span class="algolia-variant"> </span></div>
              </div>
              <div class="aa-ItemContentPrice">
                <div class="aa-ItemContentPriceCurrent">${html`${renderProductPriceCard(item, html)}`}</div>
              </div>
            </div>
          </div>
        </a>
      </li>`;
        })
        : null;


      return html`
        <div class="aa-PanelLayout aa-Panel--scrollable">
         <span class="TermsLayout">${window.BARREL.algoliaTermsText}</span>

          <p class="aa-NoResultsHeader">
            No results were found. Try searching for something else.
          </p>

          ${products && html`<div class="aa-PanelSection">
          <section class="aa-Source" data-autocomplete-source-id="saleItems">
            <div class="aa-SourceHeader">
              <div class="aa-SourceHeader">
                  <span class="aa-SourceHeaderTitle">Recommended Products</span>
                  <div class="aa-SourceHeaderLine"></div>
              </div>
            </div>
              <ul class="aa-List" role="listbox" aria-labelledby="autocomplete-0-label"
                id="autocomplete-0-saleItems-list">
                  ${products}
              </ul>
              </section>
            </div>`}
        </div>
      `;
    }
  );





  algoliaShopify.hooks.registerHook('beforeAutocompleteMainTemplate', function (_defaultTemplate, templateOptions, elements, displaySuggestions) {
    const { html } = templateOptions;
    // Don't return the default template and return a custom two-column layout instead


    return html`
        <div class="aa-PanelLayout aa-Panel--scrollable">
        <span class="TermsLayout">${window.BARREL.algoliaTermsText}</span>


        ${(elements.collections || elements.articles || elements.pages || elements.redirectUrlPlugin || elements.products)
        ?


        html` <div class="aa-PanelSections">
${(elements.collections || elements.articles || elements.pages || elements.redirectUrlPlugin) &&
          html`<div class="aa-PanelSection--left ${elements?.products ? "" : "aa-PanelSection--left--noright"}">
              ${displaySuggestions &&
            html`

                  <div class="aa-SourceHeader">
                    <span class="aa-SourceHeaderTitle"
                      >{algoliaShopify.translations.suggestions}</span
                    >
                    <div class="aa-SourceHeaderLine" />
                  </div>
                  {elements.querySuggestionsPlugin}
                `}
              ${elements.collections} ${elements.articles} ${elements.pages}
              ${elements.redirectUrlPlugin}

            </div>`
          }

           ${elements.products &&
          html`<div class="${elements.collections || elements.articles || elements.pages || elements.redirectUrlPlugin ? "aa-PanelSection--right" : "aa-PanelSection"}">
    ${elements.products}
  </div>`
          }
          </div>`
        : html`<p class="aa-NoResultsHeader">
            No results were found. Try searching for something else.
          </p>
        <div class="aa-PanelSection">
          ${elements.saleItems}
        </div>`}
        </div>
      `;
  });


  algoliaShopify.hooks.registerHook('beforeAutocompleteMainProductsTemplate', function (_defaultTemplate, templateOptions, elements) {
    const { html } = templateOptions;
    return html`
      <div class="aa-PanelLayout aa-Panel--scrollable">
       <span class="TermsLayout">${window.BARREL.algoliaTermsText}</span>

        <div class="aa-PanelSection">
          ${elements.products}
        </div>
      </div>
    `;
  });


  //added


  algoliaShopify.hooks.registerHook(
    "beforeAutocompleteProductTemplate",
    function (_defaultTemplate, templateOptions, distinct, itemLink) {
      // Modify default options, then return them
      const { html, item, components } = templateOptions;
      return html`
      <a
        href="${itemLink}"
        class="aa-ItemLink aa-ProductItem"
        onClick="${() => algoliaShopify.helpers.handleItemClick(item)}"
      >
        <div class="aa-ItemContent">
          <div class="aa-ItemPicture aa-ItemPicture--loaded">
             <img src="${item.product_image}" srcset="${item.product_image}" width="125px" height="125px" alt="${item.title}" />
          </div>
          <div class="aa-ItemContentBody">
            <div class="aa-ItemContentBrand">
              ${item.product_type &&
        components.Highlight({ hit: item, attribute: "product_type" })
        }
             removing vendor
            </div>
            <div class="aa-ItemContentTitleWrapper">
              <div class="aa-ItemContentTitle">
                ${components.Highlight({ hit: item, attribute: "title" })}
                <span class="algolia-variant">
                  ${algoliaShopify.helpers.variantTitleAddition(item, distinct)}
                </span>
              </div>
            </div>
            <div class="aa-ItemContentPrice">
              <div class="aa-ItemContentPriceCurrent">${html`${renderProductPriceCard(item, html)}`}</div>
            </div>
          </div>
        </div>
      </a>
    `;
    }
  );



  //added

  algoliaShopify.hooks.registerHook('beforeAutocompleteOptions', function (options) {
    // Change the placeholder text of the autocomplete menu
    options.placeholder = "Enter Search Term";

    //     const { searchClient } = window.algoliaShopify;
    //     const { getAlgoliaResults } = window.algoliaShopify.externals;

    //     const saleItemsPlugin = {
    //       name: 'custom-sale-items-plugin',
    //       getSources({ query }) {
    //         return [
    //           {
    //             sourceId: "saleItems",
    //             getItems() {
    //               return getAlgoliaResults({
    //                 searchClient,
    //                 queries: [{
    //                   indexName: `${algoliaShopify.config.index_prefix}products`,
    //                   params: {
    //                     query: `${window.BARREL.algoliaTermsQuery ? window.BARREL.algoliaTermsQuery : ""}`,
    //                     hitsPerPage: window.BARREL.algoliaTermsHitsPerPage ? window.BARREL.algoliaTermsHitsPerPage : 6,

    //                   },
    //                 }]
    //               })
    //             },
    //             templates: {
    //               header({ html, state }) {
    //                 return html`
    //                   <div class="aa-SourceHeader">
    //                     <span class="aa-SourceHeaderTitle">${window.BARREL.algoliaTermsTitle ? window.BARREL.algoliaTermsTitle : ""}</span>
    //                     <div class="aa-SourceHeaderLine" />
    //                   </div>`;
    //               },
    //               item({ item, components, html }) {

    //                 return html`
    //                 <a href="/products/${item.handle}/?variant=${item.objectID}" class="aa-ItemLink aa-ProductItem">

    //  <div class="aa-ItemContent">
    //  <div class="aa-ItemPicture aa-ItemPicture--loaded">
    //             <img src="${item.product_image}" srcset="${item.product_image}" width="125px" height="125px" alt="${item.title}" /></div>
    // <div class="aa-ItemContentBody">
    //                <div class="aa-ItemContentBrand">bev<span> by </span>BLACK+DECKER</div>
    //                <div class="aa-ItemContentTitleWrapper">
    //                   <div class="aa-ItemContentTitle">${item.title}<span class="algolia-variant"> </span></div>
    //                </div>
    //                <div class="aa-ItemContentPrice">

    //                     <div class="aa-ItemContentPriceCurrent">${html`${renderProductPriceCard(item, html)}`}</div>
    //                </div>
    //             </div>
    //          </div>

    // </a>
    // `;
    //               }
    //             }
    //           },
    //         ];
    //       },
    //     };
    //     options.plugins.push(saleItemsPlugin);

    async function fetchPopularProducts() {
      const query = `query($first: Int $query: String){
        products(first: $first, query: $query) {
          nodes {
            id
            title
            tags
            handle
            featuredImage {
              url
            }
            productType
            totalInventory
            variants(first: 1) {
              nodes {
                id
                availableForSale
                price{
                  amount
                }
                compareAtPrice{
                  amount
                }
              }
            }
          }
        }
      }`;
      const replaceGid = (gid) => {
        return Number(gid.split('/').at(-1))
      }

      let algoliaTermsQuery = window.BARREL.algoliaTermsQuery ? `tag:${window.BARREL.algoliaTermsQuery}` : "";
      let hitsPerPage = window.BARREL.algoliaTermsHitsPerPage ? window.BARREL.algoliaTermsHitsPerPage : 6;
      const data = await storefrontClient(query, { first: hitsPerPage, query: algoliaTermsQuery }, '2024-01');
      const products = data.data.products.nodes.map(e => {
        let variant = e.variants.nodes[0];
        return {
          id: replaceGid(e.id),
          variant_id: replaceGid(variant.id),
          title: e.title,
          handle: e.handle,
          product_image: e.featuredImage.url,
          can_order: variant.availableForSale,
          price: Number(variant?.price?.amount),
          compare_at_price: Number(variant?.compareAtPrice?.amount),
          inventory_available: e.totalInventory > 0,
          product_type: e.productType,
          tags: e.tags
        }
      });

      // console.log("rec", { products })

      return products;
    }

    fetchPopularProducts().then(products => {
      window.saleItems = products
    })


    return options;

  });

  algoliaShopify.hooks.registerHook('beforeISStartAddWidgetArray', function () {


    const { ratingMenu } = window.instantsearch.widgets;

    const ratingWidget = ratingMenu({
      container: "[class~='ais-facet-meta.BazaarVoice.Rating-container']",
      attribute: 'meta.BazaarVoice.Rating',
      templates: {
        item(data, options) {
          //  console.log(options)
          return `
            <a
              href="javascript:void()"
              class="${data.cssClasses.link}"
              aria-label="${data.name} & up">
              ${data.stars
              .map(
                isFilled => `${isFilled ?
                  `<svg class="w-[14px] h-[14px] mt-[-2px] mr-[2px]" xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none"><path fill="#D6001C" d="m7.193.353 1.614 4.97h5.226L9.806 8.394l1.614 4.97-4.227-3.071-4.228 3.071 1.615-4.97L.352 5.323h5.226L7.193.353Z" /></svg>` :
                  `<svg class="w-[14px] h-[14px] mt-[-2px] mr-[2px]" xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none"><path fill="#D2D2D2" d="m7.55.353 1.615 4.97h5.226l-4.228 3.071 1.615 4.97-4.228-3.071-4.228 3.071 1.615-4.97L.71 5.323h5.226L7.55.353Z" /></svg>`}`).join(' ')}

          <span class="${data.cssClasses.label} mleft-1 -translate-y-[2px] lg:-translate-y-[1px]"> & Up</span>
          <span class="${data.cssClasses.count} hide hidden">${data.count}</span>
        </a>
          `;
        }
      }
    });

    return [ratingWidget]
  });


  algoliaShopify.hooks.registerHook(
    "beforeInstantSearchStatsTemplate",
    function (_defaultTemplate, data, html) {
      return html`
        ${data.hasOneResult &&
        html` <span class="ais-stats--nb-results"> 1 result found </span> `}
        ${data.hasManyResults &&
        html`
          ${data.page * data.hitsPerPage + 1} - ${" "}
          ${Math.min((data.page + 1) * data.hitsPerPage, data.nbHits)} ${" "}
          out of
          <span class="ais-stats--nb-results">
            ${" "} ${algoliaShopify.helpers.formatNumber(data.nbHits)} ${" "}
            results found
          </span>
        `}
        ${data.nbPages < 2 &&
        html`<style>
          .ais-pagination-container {
            display: none !important;
          }
        </style>`}
      `;
    }
  );



  algoliaShopify.hooks.registerHook(
    "beforeInstantSearchFiltersString",
    function (defaultFilter) {
      if (defaultFilter && defaultFilter.includes("collection_ids")) {
        const collectionIDFilterRegex = /collection_ids:"\w+"/g;
        const matches = window.location.pathname.match(
          /\/collections\/([^/]+)/i
        );
        const collectionHandle =
          matches && matches.length === 2 ? matches[1] : null;
        if (collectionHandle) {
          return defaultFilter.replace(
            collectionIDFilterRegex,
            "collections:" + collectionHandle
          );
        }
      }
      return defaultFilter;
    }
  );

  algoliaShopify.hooks.registerHook(
    "beforeInstantSearchFacetItemTemplate",
    function (_defaultTemplate, item, html) {

      if (item.attribute === "tags" && item.value === "zeropriceproduct") {
        // Check if all products in this range have a price of 0
        allZeroPrice = item.count;
      }
      if (item.attribute === "price_range" && item.value === "0:10") {
        // console.log("item>>>", item);
        // console.log(allZeroPrice, "allZeroPrice for $10 & Below:", item.count);
        if (allZeroPrice === item.count) {
          localStorage.setItem('allZeroPrice', allZeroPrice);
          return html`<label class="${item.cssClasses.label} zero-price_range-disable"></label>`;
        } else if (allZeroPrice === 0 && localStorage.getItem('allZeroPrice')) {
          allZeroPrice = parseInt(localStorage.getItem('allZeroPrice')) || 0;
          if (allZeroPrice == item.count) {
            return html`<label class="${item.cssClasses.label} zero-price_range-disable"></label>`;
          } else {
            localStorage.removeItem('allZeroPrice');
          }
        } else {
          localStorage.removeItem('allZeroPrice');
        }
      }

      return html`
      <label class="${item.cssClasses.label}">
        ${item.type === "disjunctive" &&
        (item.isRefined
          ? html`
              <input
                type="checkbox"
                class="${item.cssClasses.checkbox}"
                checked
              />
            `
          : html`
              <input type="checkbox" class="${item.cssClasses.checkbox}" />
            `)}

            ${(item.attribute === "price_range" && item.value === "0:10" ? html`$10 & Below` : `${item.label}`)}

        ${(item.attribute === "product_type" ?
          html`<span class="${item.cssClasses.count}  ">
                        (${algoliaShopify.helpers.formatNumber(item.count)})
                      </span>`
          :
          html`<span class="${item.cssClasses.count}  hide hidden">
                        (${algoliaShopify.helpers.formatNumber(item.count)})
                      </span>`
        )}
      </label>
    `;
    }
  );

  algoliaShopify.hooks.registerHook(
    "beforeInstantSearchMainTemplate",
    function (_defaultTemplate, data, html) {

      // console.log("beforeInstantSearchMainTemplate", data);
      return html`
        <div class="ais-page">
          <h1 class="ais-h2">${algoliaShopify.translations.searchTitle}</h1>
          <div class="ais-input">
            <div class="ais-search-box-container"></div>
            <div class="ais-input-button">
              <div class="ais-clear-input-icon"></div>
            </div>
          </div>
          <div class="ais-facets-button">Show filters</div>
          <div class="ais-facets">
            <div class="ais-clear-refinements-container"></div>
            <div class="ais-current-refined-values-container"></div>
            ${data.facets.map(
        (facet) =>
          html`
                  <div
                    class="ais-facet-dropdown-wrapper ais-facet-${facet.type} ais-facet-${facet.escapedName} ais-${facet.title}"
                  >
                    <label
                      for="${facet.escapedName}"
                      class="ais-range-slider--header ais-facet--header ais-header"
                      >${facet.title}</label
                    >
                    <input
                      class="ais-dropdown-checkbox"
                      type="checkbox"
                      id="${facet.escapedName}"
                      name="dropdown"
                    />

                    <div
                      class="ais-facet-${facet.escapedName}-container ais-facet-dropdown-container"
                    ></div>

                  </div>
                `
      )}
          </div>
          <div class="ais-block">
            <div class="ais-search-header">
              <div class="ais-stats-container"></div>
              <div class="ais-change-display">
                <span
                  class="ais-change-display-block ais-change-display-selected"
                  ><i class="fa fa-th-large"></i
                ></span>
                <span class="ais-change-display-list"
                  ><i class="fa fa-th-list"></i
                ></span>
              </div>
              <div class="ais-sort">
                ${data.multipleSortOrders
          ? html`
                      ${algoliaShopify.translations.sortBy}
                      <span class="ais-sort-orders-container"></span>
                    `
          : html`
                      ${algoliaShopify.translations.sortBy}
                      ${algoliaShopify.translations.relevance}
                    `}
              </div>
            </div>
            <div class="ais-hits-container ais-results-as-block"></div>
          </div>
          <div class="ais-pagination-container"></div>
        </div>
      `;
    }
  );

  algoliaShopify.hooks.registerHook(
    "beforeInstantSearchNoResultTemplate",
    function (_defaultTemplate, html) {
      return html`
        <div class="ais-hit-empty">
          <div class="ais-hit-empty--title">No results were found.</div>
          <div class="ais-hit-empty--clears">
            Try searching for something else.
          </div>
        </div>
      `;
    }
  );

  algoliaShopify.hooks.registerHook(
    "beforeInstantSearchProductTemplate",
    function (_defaultTemplate, hit, html, components) {
      // console.log("hit>>>>", hit);
      // console.log("components>>>>", components);
      const MOQ_tag = hit.tags.find((s) => s.includes("MOQ_"));
      let MOQ = 0;
      if (MOQ_tag) {
        MOQ = Number(MOQ_tag.split("_")[1] || 0);
      }
      let ctaButton;
      if (hit.can_order && hit.product_type === "Bundle") {
        ctaButton = html`
          <button
            class="ais-hit--cart-button"
            data-form-id="algolia-add-to-cart-${hit.objectID}"
            disabled
          >
            ${algoliaShopify.translations.addToCart}
          </button>
        `;
      } else if (hit.can_order && hit.product_type !== "Bundle") {
        ctaButton = html`
          <button
            class="ais-hit--cart-button"
            data-form-id="algolia-add-to-cart-${hit.objectID}"
          >
            ${algoliaShopify.translations.addToCart}
          </button>
        `;
      } else if (hit.product_type === "Bundle") {
        ctaButton = html`<button
          className="btn-primary cursor-pointer w-full max-w-[183px] px-4 !m-0 h-[45px] pointer-events-none"
          disabled
        >
          Coming Soon
        </button>`;
      } else {
        ctaButton = html`<a
          href="${algoliaShopify.helpers.instantsearchLink(hit)}"
          className="btn-primary cursor-pointer w-full max-w-[183px] px-4 !m-0 h-[45px]"
        >
          View Details
        </a>`;
      }

      return html`
        <a
          href="${algoliaShopify.helpers.instantsearchLink(hit)}"
          class="ais-hit ais-product override js-product-card"
          data-algolia-index="${hit.index}"
          data-algolia-position="${hit.productPosition}"
          data-algolia-queryid="${hit.queryID}"
          data-algolia-objectid="${hit.objectID}"
          data-handle="${hit.handle}"
          data-variant-id="${hit.objectID}"
          data-distinct="${hit._distinct}"
          data-product-id="${hit.id}"
          data-qty="${MOQ || 1}"
          data-title="${hit.title}"
          data-wishlist-id=""
          data-available="${hit.can_order}"
          data-product-json="${hit ? JSON.stringify(hit) : ""}"
        >
          <div class="ais-hit--wishlist" data-heart-filled="false">
            <span class="wishlist__heart-full" data-fill="false">
              <svg
                width="24"
                height="24"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.647 2.2788C11.342 2.2788 10.0895 2.8863 9.27197 3.8463C8.45447 2.8863 7.20197 2.2788 5.89697 2.2788C3.58697 2.2788 1.77197 4.0938 1.77197 6.4038C1.77197 9.2388 4.32197 11.5488 8.18447 15.0588L9.27197 16.0413L10.3595 15.0513C14.222 11.5488 16.772 9.2388 16.772 6.4038C16.772 4.0938 14.957 2.2788 12.647 2.2788Z"
                  fill="#000"
                />
              </svg>
            </span>
            <span class="wishlist__heart-empty ">
              <svg
                width="24"
                height="24"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.77197 3.81749C10.5895 2.85749 11.842 2.24999 13.147 2.24999C15.457 2.24999 17.272 4.06499 17.272 6.37499C17.272 9.20767 14.7262 11.5162 10.869 15.0139L10.8595 15.0225L9.77197 16.0125L8.68447 15.03L8.65486 15.0031C4.80895 11.5082 2.27197 9.20274 2.27197 6.37499C2.27197 4.06499 4.08697 2.24999 6.39697 2.24999C7.70197 2.24999 8.95447 2.85749 9.77197 3.81749ZM9.77198 13.9875L9.84698 13.9125C13.417 10.68 15.772 8.54253 15.772 6.37503C15.772 4.87503 14.647 3.75003 13.147 3.75003C11.992 3.75003 10.867 4.49253 10.477 5.52003H9.07448C8.67698 4.49253 7.55198 3.75003 6.39698 3.75003C4.89698 3.75003 3.77198 4.87503 3.77198 6.37503C3.77198 8.54253 6.12698 10.68 9.69698 13.9125L9.77198 13.9875Z"
                  fill="#BDBDBD"
                />
              </svg>
            </span>
          </div>

          <img
            class="ais-hit--picture"
            src="${hit.product_image}"
            alt="${hit.title} - ${hit.variant_title}"
          />

          <div class="ais-hit--details">
            <p class="ais-hit--vendor">
              ${hit.tags && hit.tags.length > 0 && ProductBadges(hit, html)}
            </p>

            <p class="ais-hit--sku">${hit.sku}</p>
            <p class="ais-hit--title override">
              <a
                data-algolia-index="${hit.index}"
                data-algolia-position="${hit.productPosition}"
                data-algolia-queryid="${hit.queryID}"
                data-algolia-objectid="${hit.objectID}"
                href="${algoliaShopify.helpers.instantsearchLink(hit)}"
                onClick="${() => algoliaShopify.helpers.handleItemClick(hit)}"
                title="${algoliaShopify.helpers.fullTitle(
        hit.title,
        hit._distinct,
        hit.variant_title
      )}"
              >
                ${components.Highlight({ attribute: "title", hit })}
                ${algoliaShopify.helpers.variantTitleAddition(
        hit,
        hit._distinct
      )}
              </a>
            </p>
            <p class="ais-hit--price">
              ${html`${renderProductPriceCard(hit, html)}`}
            </p>
            <p class="ais-hit--moq">
              ${MOQ > 1 ? html` Must buy in multiples of ${MOQ} ` : html``}
            </p>
            ${!hit._distinct &&
        html`
              <form
                id="algolia-add-to-cart-${hit.objectID}"
                style="display: none;"
                action="/cart/add"
                method="post"
                enctype="multipart/form-data"
              >
                <input type="hidden" name="id" value="${hit.objectID}" />

                ${MOQ > 1
            ? html`
                      <input type="hidden" name="quantity" value="${MOQ}" />
                    `
            : html` <input type="hidden" name="quantity" value="1" /> `}
              </form>
              <p class="ais-hit--cart js-cta-button">${html`${ctaButton}`}</p>
            `}
            ${html`<div
              className="mt-1.5 bv-rating--hide-number ${hit?.meta?.BazaarVoice
            ?.Rating <= 0
            ? "bv-rating-custom-hide-zero"
            : ""}"
              data-bv-show="inline_rating"
              data-bv-product-id=${hit.id}
              data-bv-redirect-url=${algoliaShopify.helpers.instantsearchLink(
              hit
            )}
              data-bv-seo="false"
            ></div>`}
          </div>
        </a>
      `;
    }
  );
});


function toggleWishlist(productCard) {
  let wishlist = productCard.querySelector(".ais-hit--wishlist");
  if (wishlist.dataset.heartFilled !== "true") {
    wishlist.querySelector(".wishlist__heart-full").style.display = "block";
    wishlist.querySelector(".wishlist__heart-empty").style.display = "none";
    wishlist.setAttribute("data-heart-filled", true);
  } else {
    wishlist.querySelector(".wishlist__heart-full").style.display = "none";
    wishlist.querySelector(".wishlist__heart-empty").style.display = "block";
    wishlist.setAttribute("data-heart-filled", false);
  }
}

async function makeRequest(path, method, payload = null) {
  try {
    const endpoint = window.BARREL.sbdApiUrl;
    const token = window.BARREL.sbdApiAccessToken;
    const url = `${endpoint}/${path}`;

    const reqConfig = {
      method,
      headers: {
        token,
        mode: "no-cors",
      },
    };
    if (payload) {
      reqConfig.headers["Content-Type"] = "application/json";
      reqConfig.body = JSON.stringify(payload);
    }
    const response = await fetch(url, reqConfig);
    if (!response.ok) {
      throw new Error(`${response.status} - ${response.statusText}`);
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error makeRequest: ${method} - ${error}`);
  }
}

async function handleChangeWishlist(event) {
  //debugger;
  event.preventDefault();
  event.stopPropagation();
  const customerId = window.BARREL.customerId;
  if (!customerId) {
    window.location.href = "/account/login";
  }
  // console.log('wish click', event.target.closest('.ais-product'));
  let productCard = event.target.closest(".ais-product");

  if (productCard.dataset.wishlistId) {
    const result = await makeRequest("removeWishListItem", "POST", {
      customerId,
      wishListItemId: productCard.dataset.wishlistId,
    });
    if (result) {
      toggleWishlist(productCard);
      productCard.removeAttribute("data-wishlist-id");
    }
  } else {
    const result = await makeRequest("addWishListItem", "POST", {
      customerId,
      productId: productCard.dataset.productId,
      handle: productCard.dataset.handle,
      variantId: productCard.dataset.variantId,
      qty: productCard.dataset.qty,
      // title: productCard.dataset.title,
    });
    if (result) {
      toggleWishlist(productCard);
      productCard.setAttribute("data-wishlist-id", result.data.wishListItemId);
    }
  }
}

async function loadWishlistItems() {
  try {
    const customerId = window.BARREL.customerId;
    if (!customerId) {
      return;
    }
    const pageSize = 1000;

    const response = await makeRequest(
      `wishlist/list/${customerId}?pageSize=${pageSize}`,
      "GET"
    );
    if (response) {
      const wishlistItems = response.data;
      for (let i = 0; i < wishlistItems.length; i++) {
        const { wishListItemID, productID } = wishlistItems[i];
        const productCard = document.querySelector(
          `[data-product-id="${productID}"]`
        );
        if (productCard) {
          productCard.setAttribute("data-wishlist-id", wishListItemID);
          // toggleWishlist(productCard);
          let wishlist = productCard.querySelector(".ais-hit--wishlist");

          wishlist.querySelector(".wishlist__heart-full").style.display =
            "block";
          wishlist.querySelector(".wishlist__heart-empty").style.display =
            "none";
          wishlist.setAttribute("data-heart-filled", true);
        }
      }
    }
  } catch (error) {
    console.error("Error loadWishlistItems: ", error.message);
  }
}

document.addEventListener("algolia.instantSearch.onRender", async (e) => {
  // console.log('onrender', e);
  let wishlistItems = document.querySelectorAll(".ais-hit--wishlist");
  let productCards = document.querySelectorAll(".js-product-card");
  let priceRangeFacet = document.querySelector(".ais-facet-price_range");
  if (priceRangeFacet) {
    let priceRangeList = priceRangeFacet.querySelector(".ais-RefinementList-list");
    if (priceRangeList && priceRangeList.children.length == 1 && priceRangeList.querySelector(".ais-facet--label.zero-price_range-disable")) {
      priceRangeFacet.classList.add("hidden");
    }
  }
  await loadCTAButton(productCards);

  if (wishlistItems.length) {
    await loadWishlistItems();
    wishlistItems.forEach((ele) => {
      ele.addEventListener("click", handleChangeWishlist);
    });
  }


});

function ProductBadges(hit, html) {
  let { tags, price, compare_at_price: comparePrice } = hit;
  // console.log('product badges', { tags, price, compare_at_price: comparePrice })
  const availableForSale = true;
  const isBundle = false;

  const handleize = (string) =>
    string
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/-$/, "")
      .replace(/^-/, "");

  let badges = [];
  let uniqueBadges = [];
  let max = 2;
  if (comparePrice > price) {
    badges.push("Sale");
  }
  const labelClass =
    "mr-1 mb-1 p-1 p2 font-bold product-card__badge bg-blue text-white";
  const sortRules =
    "badge:Best Seller,badge:New,badge:Gift Guide,badge:Available on B+D,badge:,badge_custom:";
  const sortRulesArr = sortRules.split(",");

  if (tags && tags.length > 0) {
    sortRulesArr.forEach((rule) => {
      if (tags.includes(rule)) {
        badges.push(rule.replace("badge:", ""));
      }
      if (rule === "badge:") {
        const listBadge = tags.filter(
          (tag) => !sortRulesArr.includes(tag) && tag.includes("badge:")
        );
        if (listBadge.length) {
          badges = badges.concat(
            listBadge.map((tag) => tag.replace("badge:", ""))
          );
        }
      }

      if (rule === "badge_custom:") {
        const listBadgeCustom = tags.filter(
          (tag) => !sortRulesArr.includes(tag) && tag.includes("badge_custom:")
        );
        if (listBadgeCustom.length) {
          badges = badges.concat(
            listBadgeCustom.map((tag) => tag.replace("badge_custom:", ""))
          );
        }
      }
    });
  }

  if (badges.length > max) {
    badges = badges.slice(0, max);
  }

  let hideSaleTag = false;
  if (
    tags &&
    tags.includes("available:online") &&
    !tags.includes("D2C-Restriction")
  ) {
    if (availableForSale || isBundle) {
      hideSaleTag = false;
    } else {
      hideSaleTag = true;
    }
  } else {
    hideSaleTag = true;
  }
  uniqueBadges = [...new Set(badges)];
  badges = uniqueBadges;

  if (hideSaleTag) {
    badges = badges.filter((e) => e !== "Sale");
  }

  if (!badges.length) return "";

  return html`<div
    className="product-card__badges empty:hidden mt-2 mb-1 flex flex-wrap h-[25px] lg:h-[27px]"
  >
    ${badges.map((tag) => {
    return html`<div className="flex">
        <span class="badge ${labelClass} bg-${handleize(tag)}">${tag}</span>
      </div>`;
  })}
  </div>`;
}

function storefrontClient(query, variables, version) {
  version = version || '2022-07'

  //debugger;
  const endpoint = `https://${window.BARREL.shop}/api/${version}/graphql.json`;
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json", // eslint-disable-line
    "X-Shopify-Storefront-Access-Token": window.BARREL.storefrontAccessToken,
  };

  return fetch(endpoint, {
    method: method,
    headers: headers,
    body: JSON.stringify({
      query: query,
      ...(variables && { variables })
    }),
  }).then((response) => response.json());
}

const fetchProductDetails = async (id) => {
  try {
    const res = await storefrontClient(`query {
    product(id: "gid://shopify/Product/${id}") {
      bundle_components_meta: metafield(namespace: "accentuate", key: "bundle_components") {
        id
        value
      }
      free_gift_meta: metafield(namespace: "plp_filter", key: "free_gift") {
        id
        value
      }
      variants(first: 10){
        nodes{
            id
            availableForSale
        }
      }
    }
  }`);

    return {
      bundle_components_meta:
        res.data?.product?.bundle_components_meta?.value || null,
      free_gift_meta: res.data?.product?.free_gift_meta?.value || null,
      variants: res.data?.product?.variants?.nodes || [],
    };
  } catch (err) {
    console.error("error", err);
  }
};

async function loadFreeProduct(productData, freeGiftItemSKU) {
  //debugger;
  const handle = freeGiftItemSKU;
  const response = await storefrontClient(`{
        product(handle: "${handle}") {
          id
          handle
          title
          featuredImage { id }
          descriptionHtml
          onlineStoreUrl
          vendor
          variants(first: 1) {
            nodes {
              id
              title
              sku
              price { amount }
              availableForSale
            }
          }
        }
    }`);
  if (response.data) {
    const { product: freeProduct } = response.data;
    const variantAvailable = productData.variants.find(
      (variant) => variant.availableForSale
    );
    let variantAvailableId = variantAvailable?.id
      ? variantAvailable.id.replace("gid://shopify/ProductVariant/", "")
      : "";
    if (productData.product_type !== "Bundle" && !variantAvailable?.id) return;

    const activeVariant = freeProduct?.variants?.nodes?.filter(
      (variant) => variant.availableForSale
    )[0];

    if (activeVariant !== undefined) {
      const variantId = activeVariant.id.replace(
        "gid://shopify/ProductVariant/",
        ""
      );
      const formData = {
        items: [
          {
            id: variantAvailableId,
            quantity: 1,
          },
          {
            id: variantId,
            quantity: 1,
          },
        ],
        sections: "site-header,cart",
        sections_url: window.location.pathname,
      };
      document.querySelector("shopify-cart").addItem(formData);
    } else {
      const variantAvailable = productData.variants.find(
        (variant) => variant.availableForSale
      );
      if (productData.product_type !== "Bundle" && !variantAvailable?.id)
        return;

      const formData = {
        items: [
          {
            id: variantAvailableId,
            quantity: 1,
          },
        ],
        sections: "site-header,cart",
        sections_url: window.location.pathname,
      };
      document.querySelector("shopify-cart").addItem(formData);
    }
  }
}

const addToCart = async (event, productData, bundleComponents) => {
  //debugger;
  event.preventDefault();
  event.stopPropagation();
  event.stopImmediatePropagation();
  const freeGiftItemSKU = productData.free_gift;
  if (freeGiftItemSKU) {
    await loadFreeProduct(productData, freeGiftItemSKU);
  } else {
    const variantAvailable = productData.variants.find(
      (variant) => variant.availableForSale
    );
    const variantId = variantAvailable
      ? variantAvailable.id.replace("gid://shopify/ProductVariant/", "")
      : null;

    const formData = {
      items:
        productData.product_type === "Bundle" && bundleComponents?.length
          ? bundleComponents.map((componentData) => ({
            id: componentData.id.slice(componentData.id.lastIndexOf("/") + 1),
            quantity: 1,
            properties: {
              _bundle_product_id: productData.id.toString(),
              _bundle_product_title: productData.title,
              _bundle_product_image: `${productData.product_image}&width=134`,
              _bundle_product_url: `/products/${productData.handle}`,
            },
          }))
          : [
            {
              id: variantId,
              quantity: 1,
            },
          ],
      sections: "site-header,cart",
      sections_url: window.location.pathname,
    };
    await document.querySelector("shopify-cart").addItem(formData);
  }
};

async function loadCTAButton(productCards) {
  for (let i = 0; i < productCards.length; i++) {
    const item = productCards[i];
    //debugger;
    const productData = item.dataset?.productJson
      ? JSON.parse(item.dataset.productJson)
      : {};
    // console.log(productData)
    let {
      product_type: productType,
      can_order: available,
      id: productId,
      handle,
      price,
      compare_at_price: comparePrice,
      tags,
    } = productData;

    // available = JSON.parse(available);
    // price = parseFloat(price);
    // comparePrice = parseFloat(comparePrice);
    // tags = tags.split(',') || [];

    // console.log('productJson', JSON.parse(productJson))

    // console.log('loadCTAButton', { productType, available, productId, handle, price, comparePrice, tags })
    let parent = item.querySelector(".js-cta-button");

    const { bundle_components_meta, free_gift_meta, variants } =
      await fetchProductDetails(productId);
    productData["variants"] = variants;
    productData["free_gift"] = free_gift_meta;

    if (tags.includes("D2C-Restriction")) {
      let newChild = document.createElement("a");
      newChild.className =
        "btn-primary cursor-pointer w-full max-w-[183px] px-4 !m-0 h-[45px]";
      newChild.textContent = "View Details";
      newChild.href = `/products/${handle}`;
      newChild.type = "button";
      parent.replaceChild(newChild, parent.firstChild);
    } else if (tags.includes("available:online") && productType === "Bundle") {
      const handles = tags
        .filter((tag) => tag.indexOf("Bundle Components|") === 0)
        .map((tag) => tag.slice("Bundle Components|".length));

      const productsQuery = (handles = []) => {
        const handleReplace = (str) => str.replace(/-/g, "_");
        return `fragment productDetails on Product {
          tags
          variants(first: 1) {
            nodes {
              id
              price { amount }
              availableForSale
            }
          }
        }
        {
          ${handles.map(
          (handle) => `handle_${handleReplace(
            handle
          )}:product(handle: "${handle}") {
            ...productDetails
          }\n`
        )}
        }`;
      };

      const response = await storefrontClient(productsQuery(handles));

      // console.log('meta', bundle_components_meta)
      // console.log('bundle items', response.data)

      if (response.data) {
        const components = Object.keys(response.data).map((dataKey) => {
          return response.data[dataKey].variants.nodes[0];
        });
        const totalPrice = components.reduce((sum, component) => {
          return sum + Number(component.price.amount);
        }, 0);

        const product_handle = bundle_components_meta.split("|");

        let checkHandle = false;
        const compareTag = (arr1, arr2) => {
          if (arr1.length != arr2.length) {
            return false;
          }
          return (
            arr1.sort().toString().toLowerCase() ===
            arr2.sort().toString().toLowerCase()
          );
        };

        if (compareTag(handles, product_handle)) {
          checkHandle = true;
        }
        // setCheckHandle(compareFlag);
        // setProductPrice(product.variants[0].price);
        // setProductTotalPrice(totalPrice);
        let availableForSale = components.every(
          ({ availableForSale }) => availableForSale
        );
        // setCompareAtPrice(formatMoney(totalPrice * 100));
        // setBundleComponents(components);

        const availableOnline =
          Object.values(response.data).filter(({ tags }) =>
            tags.includes("available:online")
          ).length > 0;

        // console.log('check', {availableForSale , price , totalPrice , checkHandle , availableOnline})

        if (
          availableForSale &&
          price < totalPrice &&
          checkHandle &&
          availableOnline
        ) {
          let newChild = document.createElement("button");
          newChild.className =
            "btn-primary cursor-pointer w-full max-w-[183px] px-4 !m-0 h-[45px]";
          if (components.length < 1 || !availableOnline) {
            newChild.disabled = true;
          }
          newChild.textContent = "Add to Cart";
          newChild.type = "button";
          newChild.addEventListener("click", (event) =>
            addToCart(event, productData, components)
          );
          parent.replaceChild(newChild, parent.firstChild);
        } else {
          parent.firstChild.textContent = "Coming Soon";
          parent.firstChild.disabled = true;
        }
      }
    } else if (
      tags.includes("available:online") &&
      available &&
      productType !== "Bundle"
    ) {
      let newChild = document.createElement("button");
      newChild.className =
        "btn-primary cursor-pointer w-full max-w-[183px] px-4 !m-0 h-[45px]";
      newChild.textContent = "Add to Cart";
      newChild.type = "button";
      newChild.addEventListener("click", (event) =>
        addToCart(event, productData, null)
      );
      parent.replaceChild(newChild, parent.firstChild);
    } else {
      let newChild = document.createElement("a");
      newChild.className =
        "btn-primary cursor-pointer w-full max-w-[183px] px-4 !m-0 h-[45px]";
      newChild.textContent = "View Details";
      newChild.href = `/products/${handle}`;
      newChild.type = "button";
      parent.replaceChild(newChild, parent.firstChild);
    }
  }
}

function shouldDisplayProductPrice(product, isBundle) {
  // Check if the product has the required tag and doesn't contain restricted tags.
  const hasOnlineTag = product.tags.includes("available:online");
  const hasRestrictedTags =
    product.tags.includes("MAPP") || product.tags.includes("D2C-Restriction");

  // Check if the product is available or it's a bundle.
  const isProductAvailable = product.can_order || isBundle || product.inventory_available;

  // Determine whether to render the price card.
  return hasOnlineTag && !hasRestrictedTags && isProductAvailable;
}

function renderProductPriceCard(product, html) {
  const isBundle = product.product_type === "Bundle";

  if (shouldDisplayProductPrice(product, isBundle)) {
    const regularPriceLabel = "Regular Price";
    const salePriceLabel = "Sale Price";
    const isOnSale = product.compare_at_price > product.price ? true : false;

    const price = formatMoney(product.price);
    const compareAtPrice = formatMoney(product.compare_at_price);

    const priceHTML = html`
      <div
        regular-price-label="${regularPriceLabel}"
        sale-price-label="${salePriceLabel}"
        price="${price}"
        compare-at-price="${compareAtPrice}"
        ${isBundle ? "hidden" : ""}
      >
        ${isOnSale
        ? html`<div>
              <span class="sr-only"> ${regularPriceLabel} </span>
              <s class="mr-1">${compareAtPrice}</s>
              <span class="sr-only"> ${salePriceLabel} </span>
              <strong>${price}</strong>
            </div>`
        : html`<span>${price}</span>`}
      </div>
    `;

    return priceHTML;
  }
  return ""; // Return empty string if conditions aren't met.
}

function formatMoney(amount) {
  return `$${amount.toFixed(2)}`; // Adjust based on currency formatting needs.
}

function dollarsToCents(amount) {
  return Math.round(
    100 *
    parseFloat(
      typeof amount === "string" ? amount.replace(/[$,]/g, "") : amount
    )
  );
}
